.nav-tabs,
.topbar ul,
ul.contact-info,
ul.menu {
  list-style: none !important;
  padding-left: 0;
}

.node--view-mode-full {
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.site-branding-logo {
  max-height: inherit;
  img {
    max-height: 80px;
    width: auto;
    min-height: 55px;
  }
}

header .site-branding-logo img.logo-site {
  display: block !important;
}

// .tab-content a,
// .field--name-body a {
//   color: #8ec44d;
// }

.block-info {
  background-color: #f9f5f2;
  padding: 20px;
}

.gsc-tabs {
  .tabs_horizontal {
    .nav-tabs {
      border: none !important;
      border-bottom: 1px solid #ddd !important;
      > li > a {
        font-size: 18px;
      }
    }
  }
}

.logged-in .content-full {
  padding: 30px 0;
}

.post-title,
.post-meta {
  a {
    color: #18212e;

    &:hover {
      color: #8ec44d;
    }
  }
}

.gav-help-region {
  padding: 0 15px;
  margin-bottom: 10px;
}

.messages--error {
  color: red;
}
